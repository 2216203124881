<template>
  <div id="download">
    <div class="download__qr__box">
      <div class="title">
        <ul>
          <li>
            아래 버튼을 터치하면 <br>
            <b>다운로드 페이지로 이동</b>합니다 <br>
            낭만남해 앱 다운받고 <br>
            한결 편하게 사용해보세요
          </li>
          <li class="img">
            <img src="@/assets/img/download_icon.svg" alt="">
          </li>
        </ul>
      </div>
      <ul>
        <li>
          <p>
            IOS기반 <br>
            <span class="skyblue">아이폰 유저라면</span>
          </p>
          <a class="skyblue" href="https://apps.apple.com/kr/app/%EB%82%AD%EB%A7%8C%EB%82%A8%ED%95%B4/id6477441950" target="_blank">
            APP STORE
          </a>
        </li>
        <li>
          <p>
            Android기반 <br>
            <span class="darkblue">갤럭시 유저라면</span>
          </p>
          <a class="darkblue" href="https://play.google.com/store/apps/details?id=com.tourplatform.namhae&hl=ko-KR" target="_blank">
            Google Play
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPopupVisible: false,
    };
  },
  methods: {
    showPopup() {
      // this.isPopupVisible = true; // 이미지 클릭 시 팝업 표시
      this.$message.error('어플리케이션이 준비중입니다');
    },
    hidePopup() {
      this.isPopupVisible = false; // 팝업 바깥 클릭 시 팝업 숨김
    },
  },
};
</script>

<style>
#download {margin: 80px 0 0;}
#download .download__qr__box{padding: 150px 20px;width: 100%;max-width: 768px; margin: 0 auto;}
#download .download__qr__box .title {padding: 0 0 clamp(30px, 3vw, 50px);}
#download .download__qr__box .title > ul {display: flex; flex-wrap: wrap; gap: 20px;}
#download .download__qr__box .title > ul > li {flex: 0 1 calc(100% - 107px); font-size: clamp(18px, 2vw, 22px); line-height: 1.6em; word-break: keep-all; font-weight: 300;}
#download .download__qr__box .title > ul > li.img {flex: 0 1 87px;}
#download .download__qr__box .title > ul > li.img img {width: 100%;}

#download .download__qr__box > ul {display: flex; flex-wrap: wrap; gap: 15px; justify-content: center; align-items: center;}
#download .download__qr__box > ul > li {flex: 0 1 100%; padding: 50px 30px; background: #11B5DA08; display: flex; flex-wrap: wrap; align-items: end; gap: 10px;}
#download .download__qr__box > ul > li > p {flex: 0 1 calc(100% - 155px); font-size: clamp(16px, 2vw, 18px);font-weight: 600;line-height: 1.6em;}
#download .download__qr__box > ul > li > p > span {font-size: clamp(20px, 2vw, 24px); padding: 5px 0 0; display: block; font-weight: 700;}
#download .download__qr__box > ul > li > p > span.skyblue {color: #16ADD5;}
#download .download__qr__box > ul > li > p > span.darkblue {color: #5383E8;}
#download .download__qr__box > ul > li > a {flex: 0 1 145px; font-size: clamp(14px, 2vw, 15px); color: #FFF; text-align: center;padding: 15px 25px; border-radius: 5px; font-weight: 700;}
#download .download__qr__box > ul > li > a.skyblue {background: #16ADD5;}
#download .download__qr__box > ul > li > a.darkblue {background: #5383E8;}

#download .popup{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; border: 1px solid #ccc; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); padding: 100px 20px; z-index: 999; width: 100%; max-width: 768px; text-align: center; font-size: 20px; box-shadow: 0 0 0 1px rgba(0,0,0,0.04), 0 8px 16px rgba(0,0,0,0.15); border-radius: 20px;}

@media screen and (max-width: 768px) {
  #download {margin: 0;}
  #download .download__qr__box{padding: 30px 20px;}
}

@media screen and (max-width: 500px) {
  #download .download__qr__box > ul > li {padding: 30px 15px;}

  #download .download__qr__box > ul > li > p {flex: 0 1 calc(100% - 135px);}
  #download .download__qr__box > ul > li > a {flex: 0 1 125px; padding: 10px 20px;}
}

@media screen and (max-width: 375px) {
  #download .download__qr__box > ul > li {gap: 20px;}
  #download .download__qr__box > ul > li > p {flex: 0 1 100%;}
  #download .download__qr__box > ul > li > a {flex: 0 1 100%;}
}
</style>
